import { Box, Center, Icon } from "@chakra-ui/react";
import { IoWarningOutline } from "react-icons/io5";
import { ExpertInfo } from "src/components/expert-info";

export const InvalidPage = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="full"
      w="full"
      p={4}
      pos="fixed"
      textAlign="center"
    >
      <Box
        maxW="500px"
        p={4}
        border={"1px solid"}
        borderColor="orange.200"
        rounded="md"
        bg="orange.100"
      >
        <Center mb={4}>
          <Icon
            boxSize={20}
            color="orange.400"
            aria-label=""
            as={IoWarningOutline}
          />
        </Center>
        <Box>Ez a kollaborációs link már nem érvényes.</Box>
        <Box>
          Probléma esetén kérjük vegye fel a kapcsolatot az ügyintézőjével!
        </Box>
        <Box mt={2}>
          <ExpertInfo photo={false} />
        </Box>
      </Box>
    </Box>
  );
};
